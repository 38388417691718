var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper" }, [
      _c("div", [_c("b", [_vm._v("Контакты службы поддержки в странах:")])]),
      _c("div", { staticClass: "ru" }, [
        _c("div", [_c("b", [_vm._v("Россия")])]),
        _c("div", [_vm._v("Tel: +7 (495) 797-42-81")]),
        _c("div", [_vm._v("8 800 100-05-77 (бесплатно по России)")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp: +7 (999) 852-43-46")]),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href: "https://ru.coral.club/ChatSDK/index2.php?country=RU",
                target: "_blank"
              }
            },
            [_vm._v("Начать чат")]
          )
        ])
      ]),
      _c("div", { staticClass: "ua" }, [
        _c("div", [_c("b", [_vm._v("УКРАИНА")])]),
        _c("div", [_vm._v("Tel: +38 (044) 291-11-01")]),
        _c("div", [_vm._v("0 800 501 349 (бесплатно по Украине)")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp:+38 (098) 291-11-00")]),
        _c("div", [
          _c("p", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://ua.coral.club/ChatSDK/index2.php?country=UA",
                  target: "_blank"
                }
              },
              [_vm._v("Начать чат")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "ee" }, [
        _c("div", [_c("b", [_vm._v("Эстония")])]),
        _c("div", [_vm._v("Tel:+372 610 88 22")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp:+37256278919")])
      ]),
      _c("div", { staticClass: "ca" }, [
        _c("div", [_c("b", [_vm._v("США - Канада")])]),
        _c("div", [_vm._v("Tel: 1-844-224-9987")]),
        _c("div", [_vm._v("Skype: ccinorthamerica")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp: +1(437)229-3572")])
      ]),
      _c("div", { staticClass: "de" }, [
        _c("div", [_c("b", [_vm._v("Германия")])]),
        _c("div", [_vm._v("Tel +49 611 36007328")]),
        _c("div", [_vm._v("Telegram, Viber: +49 179 4197896;")]),
        _c("div", [_vm._v("WhatsApp:+49 159 02114891")])
      ]),
      _c("div", { staticClass: "pl" }, [
        _c("div", [_c("b", [_vm._v("Польша")])]),
        _c("div", [_vm._v("e-mail: coral.poland@coral-club.com")]),
        _c("div", [_vm._v("Tel: +48 22 622 36 61")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp:+48519393702")]),
        _c("div", [_vm._v("Skype: coral.poland")])
      ]),
      _c("div", { staticClass: "cz" }, [
        _c("div", [_c("b", [_vm._v("Чехия")])]),
        _c("div", [_vm._v("Tel: +420 222 521 196")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp: +420608771768")])
      ]),
      _c("div", { staticClass: "it" }, [
        _c("div", [_c("b", [_vm._v("Италия")])]),
        _c("div", [_vm._v("Tel:+ 39 0541 395124")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp: +39 3334236501")])
      ]),
      _c("div", { staticClass: "ro" }, [
        _c("div", [_c("b", [_vm._v("Румыния")])]),
        _c("div", [_vm._v("Tel: +40 314 107114; +40 314 107115")]),
        _c("div", [
          _vm._v("Telegram, WhatsApp: +40 757 101 555; +40 757 102 555")
        ])
      ]),
      _c("div", { staticClass: "lt" }, [
        _c("div", [_c("b", [_vm._v("Латвия")])]),
        _c("div", [_vm._v("Tel: +37167389200; +37126525458")]),
        _c("div", [_vm._v("Telegram, WhatsApp: +37127253377")])
      ]),
      _c("div", { staticClass: "by" }, [
        _c("div", [_c("b", [_vm._v("Беларусь")])]),
        _c("div", [_vm._v("Tel: +375 (17) 240-40-23")]),
        _c("div", [_vm._v("Telegram, Viber, WhatsApp: +375 (44) 741-08-02")])
      ]),
      _c("div", { staticClass: "il" }, [
        _c("div", [_c("b", [_vm._v("Израиль")])]),
        _c("div", [_vm._v("Tel: +972-09-865 8949")]),
        _c("div", [_vm._v("Telegram, WhatsApp: +972(54)799 2551")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }